import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../api-admin';
import TextInput from '../../feature/TextInput';
import Translated from '../../feature/Translated';
import Spinner from '../../feature/Spinner';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FormControl from 'react-bootstrap/FormControl';
import InvoiceablePeriodList from '../../feature/admin/InvoiceablePeriodList';
import withTranslate from '../../feature/withTranslate';
import classNames from 'classnames';
import FormGroup from 'react-bootstrap/FormGroup';
import ControlLabel from 'react-bootstrap/FormLabel';


class InvoicingPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.applyEditedFilters = this.applyEditedFilters.bind(this);
    this.getInvoiceablePeriods = this.getInvoiceablePeriods.bind(this);

    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const defaultThresholdEur = 20;

    this.state = {
      periods: null,
      invoices: null,
      error: null,
      errorMsg: null,
      isFetching: false,
      filters: null,
      forceIndex: 0,
      editedFilters: {
        endDate: firstDayOfMonth.toISOString(),
        threshold: defaultThresholdEur,
      }
    }
  }

  getInvoiceablePeriods() {
    this.setState({errorMsg: null, error: null, periods: null, isFetching: true});
    api.getInvoiceablePeriods()
      .then(data => {
        if (data.error) {
          this.setState({error: data.error, errorMsg: data.message, invoices: null, isFetching: false});
        } else {
          this.setState({error: null, errorMsg: null, periods: data, isFetching: false});
        }
      })
  }

  handleFilterChange(e) {
    const newFilters = Object.assign({}, this.state.editedFilters);
    newFilters[e.target.name] = e.target.value;
    this.setState({editedFilters: newFilters});
  }

  
  applyEditedFilters() {
    this.setState({filters: this.state.editedFilters, forceIndex: this.state.forceIndex + 1});
  }

  makeFormGroup(label, fieldName, classes) {
    classes = classNames('settings-field', classes);
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          className={classes}
          name={fieldName}
          placeholder={label}
          value={this.state.editedFilters[fieldName] ? this.state.editedFilters[fieldName] : ''}
          onChange={this.handleFilterChange}/>
      </FormGroup>
    );
  }

  render() {
    // Transform threshold filter from euros to cents here.
    const filters = this.state.filters && {...this.state.filters, threshold: this.state.filters.threshold * 100};

    return (
      <div className="page-container max-width-container">
        <h2><Translated translationKey="invoicing"/></h2>

        <div className="blue-box">
          {this.makeFormGroup(this.props.translate('end_date'), 'endDate')}
          {this.makeFormGroup(this.props.translate('invoicing_threshold'), 'threshold')}
           <Button onClick={this.applyEditedFilters}><Translated translationKey="apply"/></Button>
        </div>

        <InvoiceablePeriodList filters={filters} forceIndex={this.state.forceIndex}/>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(withTranslate(InvoicingPage));
