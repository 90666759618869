import React, {Component} from 'react';
import UserSearch from '../../feature/admin/UserSearch';

class AdminSearchUsersPage extends Component {

  render() {
    return (
      <div className="page-container page-gutter max-width-container">
        <UserSearch/>
      </div>
    )
  }
}

export default AdminSearchUsersPage;

