import React, {Component} from 'react';
import Translated from '../../feature/Translated';
export class InvoiceablePeriodListItem extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const period = this.props.periodData;
    return (
      <div className="blue-inner-box tight">
        <span className="strong">{period.schoolId}</span>
        <div>
          <Translated translationKey="period_count"/>: {period.periodCount}<br/>
          <Translated translationKey="invoice_total"/>: {period.invoiceTotal / 100}€<br/>
        </div>
      </div>
    );
  }

}

export default InvoiceablePeriodListItem;