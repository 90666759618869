import React, {useState} from 'react';
import {connect} from 'react-redux';
import {IoMenu} from 'react-icons/io5';
import ImageSet from './../ImageSet';
import {logout} from "../../../actions/loginActions";
import withTranslate from '../../feature/withTranslate';
import adminNavData from '../../../data/adminNavigationData';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Nav from 'react-bootstrap/Nav'
import withRouter from '../withRouter';
import {dataTypes} from "../../../actions/apiDataActions";

const AdminHeader = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  const handleListItemClick = (eventId) => {
    switch (eventId) {
      case 'logout':
        props.dispatch(logout(props.navigate));
        setIsOpen(false);
        break;
      default:
        props.navigate('/' + eventId);
        setIsOpen(false);
        break;
    }
  }

  const renderLogo = () => {
    return (
        <div className="header-logo">
          <ImageSet src='../../assets/logo-small-admin.png' className="fade-in"
                    onClick={() => props.navigate('/')}/>
        </div>
    );
  }

  const buildNavItem = (id, label) => {
    const loc = props.translate(label);

    const classes = "top-margin-small small-bottom-margin touchable";
    return (
        <Nav.Item className={classes} eventkey={id} key={id} onClick={() => handleListItemClick(id)}>
          <span className="top-margin-small">{loc}</span>
        </Nav.Item>
    );
  }

  const items = adminNavData.map(item => {
    return buildNavItem(item.id, item.titleKey)
  });

  const isMenuVisible = () => {
    return props.token !== null;
  }

  return (
      <div id="header-container" className="header-container nmd-bg-header">
        <div className="header-content page-gutter">
          {renderLogo()}
          <div>
            {isMenuVisible() &&
              <Button
                  onClick={() => setIsOpen(!isOpen)}
              >
                <IoMenu/>
              </Button>
            }
          </div>
        </div>
        <Collapse in={isOpen} className="full-navigation">
          <div>
            <Nav bsstyle="pills" className="nav-vertical">
              {items}
            </Nav>
          </div>
        </Collapse>
      </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.login.get('token')
  }
};

export default connect(mapStateToProps)(withRouter(withTranslate(AdminHeader)));
