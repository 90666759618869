import React, {Component} from 'react';
import {connect} from 'react-redux';
import withRouter from './feature/withRouter';
import {connectionStatus, maintenanceStatus, fbReady} from '../actions/actionCreators';
import {getSettings} from '../actions/settingsActions';
import AppRoutesAdmin from './AppRoutesAdmin';
import AdminHeader from './feature/admin/AdminHeader';
import Modal from 'react-bootstrap/Modal';
import Translated from './feature/Translated';
import Button from 'react-bootstrap/Button';
import Logger from '../utils/logger';
import FacebookUtil from '../utils/FacebookUtil';
import WebFont from 'webfontloader';
import Footer from './feature/Footer';

class AppAdmin extends Component {
  constructor(props, context) {
    super(props, context);
  }

  checkForSettingsLoad() {
    const hasToken = (this.props.token);
    const location = this.props.location.pathname;
    const isOnLoginRoute = location.indexOf('/login') === 0;

    if (hasToken && !isOnLoginRoute) {
      // Load settings after token is received
      // but do not do it on any of the login pages, as there might be an old token available.
      this.props.dispatch(getSettings(this.props.token.get('userId')));
    }
  }

  componentDidMount() {
    this.checkForSettingsLoad();
    if (this.props.token) {
      const encodedToken = this.props.token.get('encoded');
      Logger.setUserData({token: encodedToken});
    }
    document.body.className ='nmd-bg';
    this.initFBUtil();
    this.loadFonts();
  }

  initFBUtil() {
    FacebookUtil.init(() => this.props.dispatch(fbReady(true)), this.props.locale);
  }

  loadFonts() {
    // Needs to be configured at CSS also (_variables)!
    WebFont.load({
      google: {
        families: ['Ubuntu:300,400,700'],
        urls: 'https://fonts.googleapis.com/css?'
      }
    });
  }

  render() {
    const showModal = !this.props.hasConnection || this.props.hasMaintenance;
    let modalMessage;
    let modalAction;
    if (showModal) {
      if (!this.props.hasConnection) {
        modalMessage = 'network-error';
        modalAction = connectionStatus(true);
      } else {
        modalMessage = 'maintenance';
        modalAction = maintenanceStatus(false);
      }
    }

    return (
      <div>
        <React.StrictMode>
          <div className="body-container-wrapper">
            <div className="body-container">
              <AdminHeader className="header-container" location={this.props.location}/>
              <AppRoutesAdmin token={this.props.token}/>
              <Footer dropdownLangs={["fi", "en"]} />
            </div>
            <Modal show={showModal}>
              <Modal.Body>
                {modalMessage && <Translated translationKey={modalMessage}/>}
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => this.props.dispatch(modalAction)}>
                  <Translated translationKey="close"/>
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </React.StrictMode>
      </div>
    )
  }
}

let mapStateToProps = (state) => {
  return {
    token: state.login.get('token'),
    hasConnection: state.network.get('hasConnection'),
    hasMaintenance: state.network.get('hasMaintenance'),
    locale: state.Intl.locale
  };
};

export default withRouter(connect(mapStateToProps)(AppAdmin));
