import React, {Component, memo} from 'react';
import Reports from './Reports';
import BookModalLink from '../BookModalLink';
import ApiAdmin from '../../../api-admin';
import TextInput from '../TextInput';
import Translated from '../Translated';
import Button from 'react-bootstrap/Button';

class LiteralQuestionAdmin extends Component {

  constructor(props, context) {
    super(props, context);

    this.bookReferenceListener = this.bookReferenceListener.bind(this);
    this.saveReference = this.saveReference.bind(this);

    this.state = {
      unsavedBookReference: '',
    }
  }

  bookReferenceListener(refId) {
    this.setState({unsavedBookReference: refId});
  }

  saveReference() {
    ApiAdmin.addBookReference('literal', this.props.question.id, this.props.category, 'm_book_' + this.state.unsavedBookReference)
      .then(response => {
        if (response.bookReference) {
          this.setState({referenceError: null});
          // SMELL Mutating props is just wrong but hey..
          this.props.question.bookReferenceIds.push(response.bookReference.bookReferenceId);
          this.forceUpdate();
        } else {
          this.setState({referenceError: response});
        }
      });
  }
  
  renderBookContent(question) {
    if (question.bookReferenceIds.length === 0) {
      return (
        <div>
          <TextInput listener={(value) => this.bookReferenceListener(value)}
                     value={this.state.unsavedBookReference} placeholder="book reference id"/>
          <Button onClick={() => this.saveReference()}>
            <Translated translationKey="save"/>
          </Button>
          {this.state.referenceError && <span><Translated translationKey={this.state.referenceError.error} /></span>}
        </div>
      );
    }
    return question.bookReferenceIds.map(refId => <BookModalLink contentId={refId} showId key={refId}/>);
  }

  renderReports(reports) {
    return (reports) ? <Reports reports={reports}/> : null;
  }


  render() {
    const question = this.props.question;
    if (!question) {
      return null;
    }
    return (
      <div className="test-result-literal-wrapper">
        <h5>{question.id}. {question.question}</h5>
        {question.imageUrl && <img src={question.imageUrl} className='practise-literal-image'/>}
        <div className={question.correctAnswer === 'a' ? 'underlined' : ''}>{question.options.a}</div>
        <div className={question.correctAnswer === 'b' ? 'underlined' : ''}>{question.options.b}</div>
        <div className={question.correctAnswer === 'c' ? 'underlined' : ''}>{question.options.c}</div>
        {this.renderBookContent(question)}
        {this.renderReports(question.reports)}
      </div>
    )
  }
}

export default memo(LiteralQuestionAdmin);
