import React, {Component} from 'react';
import ApiAdmin from '../../../api-admin';
import Spinner from '../Spinner';
import InvoiceListItem from './InvoiceListItem';
import Translated from "../Translated";
import Alert from 'react-bootstrap/Alert';

const ALLOWED_FILTERS = ['startDate', 'endDate', 'stateOpen', 'schoolId'];

export class InvoiceList extends Component {

  constructor(props, context) {
    super(props, context);

    this.fetchInvoices = this.fetchInvoices.bind(this);

    this.state = {
      invoices: null,
      error: null,
      errorMsg: null,
      isFetching: false
    };
  }

  componentDidMount() {
    if (this.props.immediate) {
      this.fetchInvoices();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.haveFiltersChanged(prevProps.filters, this.props.filters)) {
      this.fetchInvoices();
    }
  }

  haveFiltersChanged(prevFilters, newFilters) {
    const a = prevFilters ? prevFilters : {};
    const b = newFilters ? newFilters : {};
    return ALLOWED_FILTERS.reduce((hasChanges, filterName) => {
      return hasChanges || (a[filterName] !== b[filterName]);
    }, false);
  }

  fetchInvoices() {
    this.setState({errorMsg: null, error: null, invoices: null, isFetching: true});
    ApiAdmin.getInvoices(this.props.filters)
      .then(data => {
        if (data.error) {
          this.setState({error: data.error, errorMsg: data.message, invoices: null, isFetching: false});
        } else {
          this.setState({error: null, errorMsg: null, invoices: data, isFetching: false});
        }
      })
  }

  render() {
    if (this.state.isFetching) {
      return <Spinner/>
    }
    if (this.state.error) {
      return <Alert><Translated translationKey={this.state.error}/></Alert>
    }
    if (!this.state.invoices) {
      return null;
    }

    const items = this.state.invoices.map(invoice => {
      return <InvoiceListItem invoiceData={invoice} key={invoice.id}/>;
    });

    return (
      <div>{items}</div>
    );
  }
}

export default InvoiceList;