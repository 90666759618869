import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translated from '../../feature/Translated';
import withTranslate from '../../feature/withTranslate';
import LoginFormEmail from '../../feature/LoginFormEmail';
import withRouter from "../../feature/withRouter";

class AdminLoginPage extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    let info;
    const redirect = this.props.location.state ? this.props.location.state.redirect : null;
    if (this.props.token && redirect) {
      info = (
        <div className="padded">
          <Translated translationKey="insufficient-rights"/>
        </div>
      );
    }

    return (
      <div id="teacher-login-page" className="page-container page-gutter max-width-container">
        <div className="front-center-wrapper">
          <div className="login-form-container fade-in">
            {info}
            <LoginFormEmail key="email-form" disableRecovery={true}/>
            <br />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.login.get('isFetching'),
    token: state.login.get('token')
  }
}

export default withRouter(connect(mapStateToProps)(withTranslate(AdminLoginPage)));
