import "./styles/style.scss";

import React from "react";
import { createRoot } from "react-dom/client";
import AppAdmin from "./components/AppAdmin";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import api from "./api";
import TranslationProvider from "./components/feature/TranslationProvider";
import Logger from "./utils/logger";
import { clientSideAdmin } from "./localizations/clientSideAdmin";

Logger.initialize();

api.store = store;

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <TranslationProvider injectedLocalizations={[clientSideAdmin]}>
      <Router>
        <Routes>
          <Route path="/*" element={<AppAdmin />} />
        </Routes>
      </Router>
    </TranslationProvider>
  </Provider>
);
