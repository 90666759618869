import React, {Component} from 'react';
import ApiAdmin from '../../../api-admin';
import Spinner from '../Spinner';
import InvoiceablePeriodListItem from './InvoiceablePeriodListItem';
import Translated from '../Translated';
import Button from 'react-bootstrap/Button';
import InvoiceListItem from './InvoiceListItem';
import FormGroup from 'react-bootstrap/FormGroup';
import ControlLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import classNames from 'classnames';
import withTranslate from '../withTranslate';

const ALLOWED_FILTERS = ['endDate', 'threshold'];

export class InvoiceablePeriodList extends Component {

  constructor(props, context) {
    super(props, context);

    this.fetchInvoiceablePeriods = this.fetchInvoiceablePeriods.bind(this);
    this.postCreate = this.postCreate.bind(this);
    this.state = {
      periods: null,
      error: null,
      errorMsg: null,
      isFetching: false,
      forceIndex: props.forceIndex,
      invoiceDate: null,
      dueDate: null
    };
  }

  componentDidMount() {
    if (this.props.immediate) {
      this.fetchInvoiceablePeriods();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.haveFiltersChanged(prevProps.filters, this.props.filters) || this.props.forceIndex !== prevProps.forceIndex) {
      this.fetchInvoiceablePeriods();
    }
  }

  haveFiltersChanged(prevFilters, newFilters) {
    const a = prevFilters ? prevFilters : {};
    const b = newFilters ? newFilters : {};
    return ALLOWED_FILTERS.reduce((hasChanges, filterName) => {
      return hasChanges || (a[filterName] !== b[filterName]);
    }, false);
  }

  fetchInvoiceablePeriods() {
    this.setState({errorMsg: null, error: null, periods: null, isFetching: true});

    ApiAdmin.getInvoiceablePeriods(this.props.filters)
      .then(data => {
        if (data.error) {
          this.setState({error: data.error, errorMsg: data.message, periods: null, isFetching: false});
        } else {
          this.setState({error: null, errorMsg: null, periods: data, isFetching: false});
        }
      })
  }

  postCreate() {
    // TODO Create invoices for user selected schools. For now, send all schoolIds we have
    const schoolIds = this.state.periods.map(period => period.schoolId);

    this.setState({errorMsg: null, error: null, invoices: null, isFetching: true});
    ApiAdmin.createInvoices(schoolIds, this.props.filters, this.state.invoiceDate, this.state.dueDate)
      .then(data => {
        if (data.error) {
          this.setState({error: data.error, errorMsg: data.message, invoices: null, isFetching: false});
        } else {
          this.setState({error: null, errorMsg: null, invoices: data, isFetching: false});
        }
      })
  }

  makeFormGroup(label, fieldName, classes) {
    classes = classNames('settings-field', classes);
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          className={classes}
          name={fieldName}
          placeholder={label}
          value={this.state[fieldName] ? this.state[fieldName] : ''}
          onChange={e => this.setState({[e.target.name]: e.target.value})}
          />
      </FormGroup>
    );
  }

  render() {
    if (this.state.isFetching) {
      return <Spinner/>
    }
    if (!this.state.periods) {
      return null;
    }

    let createInvoicesButton;
    if (this.state.periods && this.state.periods.length && !this.state.invoices) {
      createInvoicesButton = (
        <div>
          {this.makeFormGroup(this.props.translate('invoice_date'), 'invoiceDate')}
          {this.makeFormGroup(this.props.translate('due_date'), 'dueDate')}
          <Button className="top-margin" bsstyle="warning" onClick={this.postCreate}>
            <Translated translationKey="create_invoices"/>
          </Button>
        </div>
      );
    }

    const items = !this.state.invoices && this.state.periods.map(period => {
      return <InvoiceablePeriodListItem periodData={period} key={period.schoolId}/>;
    });

    let invoices;
    if (this.state.invoices) {
      invoices = this.state.invoices.map(invoice => {
        return <InvoiceListItem invoiceData={invoice} key={invoice.id}/>;
      })
    }

    return (
      <div>
        <div>{items}</div>
        {this.state.error ? <div>{this.state.error} {this.state.errorMsg}</div> : null}
        {createInvoicesButton}
        <div>{invoices}</div>
      </div>
    );
  }
}

export default withTranslate(InvoiceablePeriodList);