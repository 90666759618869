import React, {Component, useEffect} from 'react';
import {Route, Routes, useNavigate, useLocation} from 'react-router-dom'
import AdminDashboardPage from './pages/admin/AdminDashboardPage'
import AdminSearchUsersPage from './pages/admin/AdminSearchUsersPage'
import AdminSchoolAddPage from './pages/admin/AdminSchoolAddPage'
import AdminLoginAsUserPage from './pages/admin/AdminLoginAsUserPage'
import AdminSchoolSettingsPage from './pages/admin/AdminSchoolSettingsPage'
import AdminPractiseLimitsPage from './pages/admin/AdminPractiseLimitsPage'
import AdminLoginPage from './pages/admin/AdminLoginPage'
import OrdersPage from './pages/OrdersPage'
import AdminListLiteralsPage from './pages/admin/AdminListLiteralsPage'
import AdminListImagesPage from './pages/admin/AdminListImagesPage'
import NotFoundPage from './pages/NotFoundPage'
import {Roles, fillsRole} from '../data/roles';
import BookSimplePage from './pages/BookSimplePage';
import BookSelectionPage from './pages/BookSelectionPage';
import PreCreateKeysPage from './pages/admin/PreCreateKeysPage';
import InvoicingPage from './pages/admin/InvoicingPage';
import InvoicingListPage from './pages/admin/InvoicingListPage';
import SchoolInvoicesPage from './pages/admin/SchoolInvoicesPage';
import AdminWebshopOrdersPage from './pages/admin/AdminWebshopOrdersPage';
import TeachersListPage from "./pages/TeachersListPage";
import AdminEditUserPage from "./pages/admin/AdminEditUserPage";
import AdminSearchEmailEventsPage from './pages/admin/AdminSearchEmailEventsPage';
import AdminEventLogsPage from './pages/admin/AdminEventLogsPage';

const RequireLoginIndex = ({token, children}) => {
  const isAdminLoggedIn = token !== null && fillsRole(token.get('role'), Roles.ADMIN);
  return isAdminLoggedIn ? children : <AdminLoginPage/>
};

const renderIndexRoute = (path, Component, token) => (
  <Route path={path} element={
    <RequireLoginIndex token={token}>
      <Component/>
    </RequireLoginIndex>
  }/>
)

const RequireAdminLogin = ({token, children}) => {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token || !fillsRole(token.get('role'), Roles.ADMIN)) {
      return navigate('/login', {state: {redirect: location}})
    }
  }, [token, navigate, location])

  return token ? children : null;
};

const renderRequireAdminLoginRoute = (path, Component, token) => (
  <Route path={path} element={
    <RequireAdminLogin token={token}>
      <Component/>
    </RequireAdminLogin>
  }/>
)


const AppRoutesAdmin = ({token}) => (
  <Routes>
    {renderIndexRoute('/', AdminDashboardPage, token)}
    <Route path="/login" element={<AdminLoginPage/>}/>
    {renderRequireAdminLoginRoute('/dash', AdminDashboardPage, token)}
    {renderRequireAdminLoginRoute('/search-users', AdminSearchUsersPage, token)}
    {renderRequireAdminLoginRoute('/school-add/', AdminSchoolAddPage, token)}
    {renderRequireAdminLoginRoute('/login-as-user/', AdminLoginAsUserPage, token)}
    {renderRequireAdminLoginRoute('/school-settings/:schoolId', AdminSchoolSettingsPage, token)}
    {renderRequireAdminLoginRoute('/school-limits/:schoolId', AdminPractiseLimitsPage, token)}
    {renderRequireAdminLoginRoute('/school-orders/:schoolId', OrdersPage, token)}
    {renderRequireAdminLoginRoute('/school-invoices/:schoolId', SchoolInvoicesPage, token)}
    {renderRequireAdminLoginRoute('/school-teachers/:schoolId', TeachersListPage, token)}
    {renderRequireAdminLoginRoute('/list-literals', AdminListLiteralsPage, token)}
    {renderRequireAdminLoginRoute('/list-images', AdminListImagesPage, token)}
    {renderRequireAdminLoginRoute('/books', BookSelectionPage, token)}
    {renderRequireAdminLoginRoute('/books/:bookId/:language/:chapterId', BookSimplePage, token)}
    {renderRequireAdminLoginRoute('/books/:bookId/:language/', BookSimplePage, token)}
    {renderRequireAdminLoginRoute('/precreate', PreCreateKeysPage, token)}
    {renderRequireAdminLoginRoute('/invoicing', InvoicingPage, token)}
    {renderRequireAdminLoginRoute('/invoicing-list', InvoicingListPage, token)}
    {renderRequireAdminLoginRoute('/webshop-orders', AdminWebshopOrdersPage, token)}
    {renderRequireAdminLoginRoute('/user/:userId', AdminEditUserPage, token)}
    {/*Router v6 does not support optional parameters, has to be 2 seperate routes.*/} 
    {renderRequireAdminLoginRoute('/email-events/', AdminSearchEmailEventsPage, token)} 
    {renderRequireAdminLoginRoute('/email-events/:email', AdminSearchEmailEventsPage, token)}
    {renderRequireAdminLoginRoute('/event-logs', AdminEventLogsPage, token)}
    <Route element={NotFoundPage}/>
  </Routes>
);

export default AppRoutesAdmin;
