import React, {Component} from 'react';
import {connect} from 'react-redux';
import withTranslate from '../../feature/withTranslate';
import SchoolAdd from '../../feature/admin/SchoolAdd';

class AdminSchoolSettingsPage extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {
    const t = this.props.translate;

    return (
      <div id="school-settings-page" className="page-container max-width-container">
        <h1>{t('menu_add_school')}</h1>

        <SchoolAdd />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(withTranslate(AdminSchoolSettingsPage));
