import React, {useState} from 'react';
import {loadData, dataTypes} from '../../../actions/apiDataActions'
import {connect} from 'react-redux';
import ApiAdmin from '../../../api-admin';
import withRouter from '../withRouter';
import Button from 'react-bootstrap/Button';
import Translated from './../Translated';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {IoSearch} from 'react-icons/io5';
import withTranslate from './../withTranslate';
import Spinner from './../../feature/Spinner';
import Alert from 'react-bootstrap/Alert';
import { IoCheckmarkSharp } from "react-icons/io5";

function UserSearch(props) {

  const [term, setTerm] = useState('');

  const searchUsers = (searchTerm) => {
    props.dispatch(loadData(dataTypes.USER_SEARCH, ApiAdmin.searchUsers, searchTerm));
  }

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    searchUsers(term);
  }

  const renderUser = (user) => {
    return (
        <div key={user.id} className="blue-inner-box tight">
          <span
              className="touchable"
              onClick={() => props.navigate(`/user/${encodeURIComponent(user.id)}`)}
          >
            {user.id.toUpperCase()}, {user.name}, {user.schoolId.toUpperCase()}
          </span>
        </div>
    );
  }

  const renderAAUser = (user) => {
    return (
        <div key={user.ajokaistaUserId} className="blue-inner-box tight">
          <span
              className="touchable"
              onClick={() => props.navigate(`/user/${encodeURIComponent(user.ajokaistaUserId)}`)}
          >
            {user.ajokaistaUserId.toUpperCase()}, {user.userName}, {user.userEmail}
          </span>
        </div>
    );
  }

  const renderEaUser = (user) => {
    return (
        <div key={user.ajokaistaUserId} className="blue-inner-box tight">
          <span
              className="touchable"
              onClick={() => props.navigate(`/user/${encodeURIComponent(user.ajokaistaUserId)}`)}
          >
            {user.ajokaistaUserId.toUpperCase()}, {user.userName}, {user.userEmail}
          </span>
        </div>
    );
  }

  const renderGenericIntegrationUser = (user) => {
    return (
        <div key={user.userId} className="blue-inner-box tight">
          <span
              className="touchable"
              onClick={() => props.navigate(`/user/${encodeURIComponent(user.userId)}`)}
          >
            {user.integrationId.toUpperCase()}, {user.userId.toUpperCase()}, {user.userName}, {user.userEmail}
          </span>
        </div>
    );
  }

  const renderVirtuaaliteoriaUser = (user) => {
    return (
        <div key={user.ajokaistaUserId} className="blue-inner-box tight">
          <span
              className="touchable"
              onClick={() => props.navigate(`/user/${encodeURIComponent(user.ajokaistaUserId)}`)}
          >
            {user.ajokaistaUserId.toUpperCase()}, {user.userName}, {user.userEmail}
          </span>
        </div>
    );
  }

  const renderEmailRequest = (request) => {
    return (
        <div key={request.id} className="blue-inner-box tight">
          <div
              className="touchable"
              onClick={() => props.navigate(`/user/${encodeURIComponent(request.userId)}`)}
          >
            {request.email}, {request.userName}
          </div>
          <div>
            {request.schoolId.toUpperCase()}, {request.role}, {request.requestDate}, {request.action}, [{request.consumeDate}]
          </div>
        </div>
    );
  }

  const renderEmailCredential = (credential) => {
    return (
        <div key={credential.id} className="blue-inner-box tight">
          <div
              className="touchable"
              onClick={() => props.navigate(`/user/${encodeURIComponent(credential.userId)}`)}
          >
            {credential.userId}, {credential.email}
          </div>
          <div>
            {credential.hasPassword ? <IoCheckmarkSharp /> : 'X'}, {credential.createdDate}, {credential.isDeleted}
          </div>
        </div>
    );
  }

  const renderFacebookCredential = (credential) => {
    return (
        <div key={credential.id} className="blue-inner-box tight">
          <div
              className="touchable"
              onClick={() => props.navigate(`/user/${encodeURIComponent(credential.userId)}`)}
          >
            {credential.userId}, {credential.name}
          </div>
        </div>
    );
  }

  const renderResultItem = (resultCatId, resultItem) => {
    switch (resultCatId) {
      case 'users':
        return renderUser(resultItem);

      case 'emailRequests':
        return renderEmailRequest(resultItem);

      case 'emailCredentials':
        return renderEmailCredential(resultItem);

      case 'facebookCredentials':
        return renderFacebookCredential(resultItem);

      case 'aaIntegrationUsers':
        return renderAAUser(resultItem);

      case 'virtuaaliteoriaUsers':
        return renderVirtuaaliteoriaUser(resultItem);

      case 'eaUsers':
        return renderEaUser(resultItem);

      case 'genericIntegrationUsers':
        return renderGenericIntegrationUser(resultItem);

      default:
        return (
            <div>
              {JSON.stringify(resultItem)};
            </div>
        );
    }
  }

  const renderResults = () => {
    if (props.isFetching) {
      return (<Spinner/>);
    }

    if (props.error) {
      return (
          <Alert>
            <Translated translationKey={props.error}/>
          </Alert>
      );
    }

    if (!props.results) {
      return null;
    }

    const resultCategoriesNames = Object.keys(props.results);
    const results = [];
    resultCategoriesNames.forEach(catId => {
      const items = props.results[catId].map(item => renderResultItem(catId, item));
      results.push(
          <div key={catId}>
            <h3 className="medium-top-margin">{catId}</h3>
            {items}
            <hr/>
          </div>
      )
    });
    return results;
  }

  return (

      <div className="user-list">
        <div id="user-search-container">
          <Form onSubmit={(e) => onSubmit(e)} className="fade-in">
            <Form.Group>
              <InputGroup>
                <InputGroup.Text><IoSearch/></InputGroup.Text>
                <Form.Control type="text" name="term" placeholder={props.translate('search_users')}
                              value={term} onChange={(e) => setTerm(e.target.value)}/>
                <InputGroup>
                  <Button
                      id="user-search-button"
                      onClick={(e) => onSubmit(e)}
                  >
                    {props.translate('search_do')}
                  </Button>
                </InputGroup>
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
        <div className="large-top-margin">
          {renderResults()}
        </div>
      </div>
  )

}

let mapStateToProps = (state) => {
  const search = state.apiData[dataTypes.USER_SEARCH];
  return ({
    results: search.data,
    isFetching: search.isFetching,
    error: search.error,
  });
};

export default withRouter(connect(mapStateToProps)(withTranslate(UserSearch)));