import React, {Component} from 'react';
import ApiAdmin from '../../../api-admin';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import withTranslate from './../withTranslate';
import Spinner from './../../feature/Spinner';
import Alert from 'react-bootstrap/Alert';
import Translated from '../Translated';


export class SchoolComments extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      newComment: '',
      comments: null,
      isFetching: false,
      error: null
    }
  }

  componentDidMount() {
    this.fetchComments();
  }

  fetchComments() {
    if (this.state.isFetching) {
      return false;
    }
    this.setState({
      comments: null,
      isFetching: true
    });

    ApiAdmin.getSchoolComments(this.props.schoolId)
      .then(comments => {
        this.setState({
          comments,
          isFetching: false
        });
      })
  }

  addComment(comment) {
    this.setState({
      isFetching: true
    });
    ApiAdmin.addSchoolComment(this.props.schoolId, comment)
      .then(response => {
        if (response.error) {
          this.setState({
            error: response.error,
            isFetching: false
          })
        } else {
          this.setState({
            comments: response,
            isFetching: false
          });
        }
      })
  }

  onSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    if (this.state.newComment.trim().length === 0) {
      return this.setState({
        error: <Translated translationKey="invalid-input" />,
      });
    }

    this.addComment(this.state.newComment);

    this.setState({ newComment: "" });
  }

  renderComments() {
    if (this.state.isFetching) {
      return <Spinner />
    }

    if (this.state.error) {
      return <Alert>{this.state.error}</Alert>
    }

    const comments = this.state.comments;
    if (!comments || comments.length === 0) {
      return null;
    }

    const commentElems = [];
    comments.forEach(comment => {
      commentElems.push(
        <p key={comment.id}>
          <span className="semi-transparent">
            {!comment.user.userName || comment.user.userName.length === 0 ? (
              <Translated translationKey="not_available" />
            ) : (
              comment.user.userName
            )}
            , {comment.localDate}
          </span>
          <br />
          {comment.comment}
        </p>
      );
    });
    return commentElems;
  }

  render() {
    const t = this.props.translate;

    return (
      <div className="school-list">
        <div id="user-search-container">
          <Form onSubmit={(e) => this.onSubmit(e)} className="fade-in">
            <Form.Group className="mb-3">
              <InputGroup>
                <Form.Control type="text" name="newComment" placeholder={t('add_comment')}
                             value={this.state.newComment}
                             onChange={(e) => this.setState({newComment: e.target.value})}
                             onFocus={() => this.setState({error:null})}/>
                <InputGroup>
                  <Button
                      className="top-margin-small"
                    id="add-comment-button"
                    onClick={(e) => this.onSubmit(e)}
                  >
                    {t("add_comment")}
                  </Button>
                </InputGroup>
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
        <br />
        {this.renderComments()}
      </div>
    )
  }

}

export default withTranslate(SchoolComments);