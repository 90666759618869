import React, {Component} from 'react';
import Translated from '../../feature/Translated';
import Button from 'react-bootstrap/Button';
import {ImFire} from 'react-icons/im';
import {IoChatbubblesSharp} from 'react-icons/io5';
import Modal from 'react-bootstrap/Modal';
import dateFormat from '../../../utils/date';
import classNames from 'classnames';

export class Reports extends Component {

  constructor(props, context) {
    super(props, context);
    this.showReportsModalListener = this.showReportsModalListener.bind(this);

    this.state = {
      showReportsModal: false,
    }

  }

  showReportsModalListener(show) {
    this.setState({showReportsModal: show});
  }

  render() {
    const reportElems = [];


    this.props.reports.forEach(report => {
      let elem = (<div key={report.id} className="large-top-margin">{dateFormat.date(report.date)} {report.message}</div>);
      reportElems.push(elem);
    });

    const reportsCount = reportElems.length;
    const isHot = reportsCount > 2;

    const classes = classNames('underlined', 'touchable');

    let countElem = (
      <div className={classes} onClick={() => this.setState({showReportsModal: true})}>
        <Translated translationKey="reports"/> {reportsCount}&nbsp;
        {isHot
          ? <span><IoChatbubblesSharp className="red"/><ImFire className="red"/> </span>
          : <IoChatbubblesSharp />}
      </div>);

    let reportsModal = null;
    if (this.state.showReportsModal) {
      reportsModal = (
        <Modal id="book-content-modal" show={true} className="gray-light">
          <Modal.Body>
            {reportElems}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="close"
              onClick={() => {
                this.showReportsModalListener(null);
              }}
            >
              <Translated translationKey="close"/>
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return (
      <div>
        {countElem}
        {reportsModal}
      </div>
    )
  }
}

export default Reports;