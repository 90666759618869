import React, { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import ApiAdmin from "../../../api-admin";
import DropdownInput from "../DropdownInput";
import { useTranslate } from "../TranslationProvider";
import Spinner from "../Spinner";

const DEFAULT_VALIDITY_PERIOD_DAYS = 14;

const AddMaterialModal = ({
  userId,
  fetchUserLicenses,
  isAddLicenseModal,
  setIsAddLicenseModal,
  materialTypes,
  materialIds,
}) => {
  const t = useTranslate();
  const [materialType, setMaterialType] = useState(materialTypes[0]);
  const [materialId, setMaterialId] = useState(materialIds[0]);
  const [validForDays, setValidForDays] = useState(DEFAULT_VALIDITY_PERIOD_DAYS);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(null);

  const addLicense = async () => {
    setIsSaving(true);
    ApiAdmin.addMaterialLicense(userId, {
      materialType,
      materialId,
      validForDays,
    }).then((response) => {
      if (response.error) {
        setError(response.error);
        setIsSaving(false);
        return;
      }

      setIsSaving(false);
      fetchUserLicenses();
      setIsAddLicenseModal(false);
    });
  };

  return (
    <Modal show={isAddLicenseModal} onHide={() => setIsAddLicenseModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{t("license_information")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>{t("type") + ":"}</Form.Label>
        <DropdownInput
          listener={(value) => setMaterialType(value)}
          value={materialType}
          title={materialType}
          items={materialTypes.map(materialType => [materialType, materialType])}
        />
        <Form.Label>{t("id") + ":"}</Form.Label>
        <DropdownInput
          listener={(value) => setMaterialId(value)}
          value={materialId}
          title={materialId}
          items={materialIds.map(materialId => [materialId, materialId])}
        />
        <Form.Group className={"mb-3"}>
          <Form.Label>{t("duration_days")}</Form.Label>
          <Form.Control
            type="number"
            name="input"
            placeholder={t("duration_days")}
            value={validForDays}
            onChange={(e) =>
              setValidForDays(parseInt(e.target.value, 10) || "")
            }
          />
        </Form.Group>
        {error && <Alert>{t(error)}</Alert>}
        {isSaving && <Spinner />}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={addLicense}>{t("add")}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddMaterialModal;
