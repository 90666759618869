import React, {useState, useEffect} from "react";
import SingleItemAccordion from "../SingleItemAccordion";
import Spinner from "../Spinner";
import { useTranslate } from "../TranslationProvider";
import ApiAdmin from "../../../api-admin";
import Alert from 'react-bootstrap/Alert';
import Translated from "../Translated";
import { Button } from "react-bootstrap";
import { RiCloseFill } from "react-icons/ri";
import DateFormat from "../../../utils/date";
import AddMaterialModal from "./AddMaterialModal";
import AddCategoryModal from "./AddCategoryModal";

const LicenseTypes = {
  category: "category",
  material: "material",
};

const AdminListUserLicenses = function ({ userId }) {
  const [fetchData, setFetchData] = useState({
    licenses: null,
    isFetching: false,
    error: null,
  });
  const t = useTranslate();
  const categories = fetchData.licenses?.categoryLicenses;
  const materials = fetchData.licenses?.materialLicenses;
  const selections = fetchData.licenses?.selections;
 
  const fetchUserLicenses = () => {
    setFetchData(prev => {
      return { ...prev, isFetching: true, error: null };
    } );

    ApiAdmin.getUserLicenses(userId).then((response) => {
      if (response.error) {
       return setFetchData({
         licenses: null,
         isFetching: false,
         error: response.error,
       });
      } else {
        return setFetchData({
          licenses: response,
          isFetching: false,
          error: null,
        });
      }
    });
  }

  useEffect(() => {
    fetchUserLicenses();
  }, []);

  const renderContent = (userId) => {
    return (
      <div>
        <SingleItemAccordion
          title={
            <div>
              {t("category_licenses")}&nbsp;
              {fetchData.isFetching && <Spinner />}
            </div>
          }
          content={
            <CategoriesAccordionContent
              selections={selections?.category}
              userId={userId}
              categories={categories}
              fetchUserLicenses={fetchUserLicenses}
            />
          }
        />
        <SingleItemAccordion
          title={
            <div>
              {t("material_licenses")}&nbsp;
              {fetchData.isFetching && <Spinner />}
            </div>
          }
          content={
            <MaterialsAccordionContent
              selections={selections?.material}
              userId={userId}
              materials={materials}
              fetchUserLicenses={fetchUserLicenses}
            />
          }
        />
      </div>
    );
  };

  return (
    <div>
      {fetchData.error && <Alert>{fetchData.error}</Alert>}
      {!fetchData.error && renderContent(userId)}
    </div>
  );
};

const CategoriesAccordionContent = ({
  userId,
  categories,
  selections,
  fetchUserLicenses
}) => {
  const t = useTranslate();
  const [isSaving, setIsSaving] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [error, setError] = useState(false);
  const [isAddLicenseModal, setIsAddLicenseModal] = useState(false);

  const removeLicense = async (licenseId) => {
    setDeletingId(licenseId);
    setIsSaving(true);
    ApiAdmin.removeLicense(userId, {
      licenseId,
      licenseType: LicenseTypes.category,
    }).then((response) => {
      if (response.error) {
        setError(response.error);
        setIsSaving(false);
        return;
      }

      setDeletingId(null);
      setIsSaving(false);
      fetchUserLicenses();
    });
  };

  return (
    <div className="admin-user-categories padded-small">
      {selections && <AddCategoryModal
        categoryItems={selections.categorySelection}
        userId={userId}
        isAddLicenseModal={isAddLicenseModal}
        setIsAddLicenseModal={setIsAddLicenseModal}
        fetchUserLicenses={fetchUserLicenses}
      />}
      <Button onClick={() => setIsAddLicenseModal(true)}>
        <Translated translationKey="add" />
      </Button>
      <div className="custom-table full-width">
        <div className="custom-table-header">
          <div className="ct-column-name">
            <Translated translationKey="category" />
          </div>
          <div className="ct-column-name">
            <Translated translationKey="valid_until" />
          </div>
          <div className="ct-column-name">
            <Translated translationKey="group_id" />
          </div>
          <div className="ct-column-name"></div>
        </div>
        <div className="ct-table-body">
          {categories?.map((category) => {
            const { id, validUntil, groupId } = category;
            const daysLeft = validUntil
              ? DateFormat.getRemainingDays(validUntil)
              : null;

            return (
              <div key={id} className="ct-table-row">
                <div className="ct-table-body-cell">
                  <strong className="ct-mobile-only">
                    <Translated translationKey="category" />:
                  </strong>
                  &nbsp;
                  {t(category.category)}
                </div>
                <div className="ct-table-body-cell">
                  <strong className="ct-mobile-only">
                    <Translated translationKey="valid_until" />:
                  </strong>
                  &nbsp;
                  {validUntil} {daysLeft && `(${daysLeft} ${t("days")})`}
                </div>
                <div className="ct-table-body-cell">
                  {groupId && (
                    <>
                      <strong className="ct-mobile-only">
                        <Translated translationKey="group_id" />:
                      </strong>
                      &nbsp;
                      {groupId}
                    </>
                  )}
                </div>
                <div className="ct-table-body-cell">
                  {id === deletingId && isSaving && <Spinner />}
                  {!groupId && (
                    <>
                      <RiCloseFill
                        className="ct-desktop-only touchable"
                        onClick={() => removeLicense(id)}
                      />
                      <Button
                        className="ct-mobile-only touchable"
                        onClick={() => removeLicense(id)}
                      >{t("delete")}</Button>
                    </>
                  )}
                  {id === deletingId && error && <Alert>{t(error)}</Alert>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const MaterialsAccordionContent = ({
  userId,
  materials,
  fetchUserLicenses,
  selections
}) => {
  const t = useTranslate();
  const [isSaving, setIsSaving] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [error, setError] = useState(false);
  const [isAddLicenseModal, setIsAddLicenseModal] = useState(false);

  const removeLicense = async (licenseId) => {
    setDeletingId(licenseId);
    setIsSaving(true);
    ApiAdmin.removeLicense(userId, {
      licenseId,
      licenseType: LicenseTypes.material,
    }).then((response) => {
      if (response.error) {
        setError(response.error);
        setIsSaving(false);
        return;
      }

      setDeletingId(null);
      setIsSaving(false);
      fetchUserLicenses();
    });
  };

  return (
    <div className="admin-user-categories padded-small">
      {selections && (
        <AddMaterialModal
          materialTypes={selections.materialTypeSelection}
          materialIds={selections.materialIdSelection}
          userId={userId}
          isAddLicenseModal={isAddLicenseModal}
          setIsAddLicenseModal={setIsAddLicenseModal}
          fetchUserLicenses={fetchUserLicenses}
        />
      )}
      <Button>
        <Translated
          onClick={() => setIsAddLicenseModal(true)}
          translationKey="add"
        />
      </Button>
      <div className="custom-table full-width">
        <div className="custom-table-header">
          <div className="ct-column-name">
            <Translated translationKey="type" />
          </div>
          <div className="ct-column-name">
            <Translated translationKey="id" />
          </div>
          <div className="ct-column-name">
            <Translated translationKey="valid_until" />
          </div>
          <div className="ct-column-name">
            <Translated translationKey="group_id" />
          </div>
          <div className="ct-column-name"></div>
        </div>
        <div className="ct-table-body">
          {materials?.map((material) => {
            const { id, materialType, materialId, validUntil, groupId } =
              material;
              const daysLeft = validUntil
              ? DateFormat.getRemainingDays(validUntil)
              : null;

            return (
              <div key={id} className="ct-table-row">
                <div className="ct-table-body-cell">
                  <strong className="ct-mobile-only">
                    <Translated translationKey="type" />:
                  </strong>
                  &nbsp;
                  {t(materialType)}
                </div>
                <div className="ct-table-body-cell">
                  <strong className="ct-mobile-only">
                    <Translated translationKey="id" />:
                  </strong>
                  &nbsp;
                  {t(materialId)}
                </div>
                <div className="ct-table-body-cell">
                  <strong className="ct-mobile-only">
                    <Translated translationKey="valid_until" />:
                  </strong>
                  &nbsp;
                  {validUntil} {daysLeft && `(${daysLeft} ${t("days")})`}
                </div>
                <div className="ct-table-body-cell">
                  {groupId && (
                    <>
                      <strong className="ct-mobile-only">
                        <Translated translationKey="group_id" />:
                      </strong>
                      &nbsp;
                      {groupId}
                    </>
                  )}
                </div>
                <div className="ct-table-body-cell">
                  {id === deletingId && isSaving && <Spinner />}
                  {!groupId && (
                    <>
                    <RiCloseFill
                      className="ct-desktop-only touchable"
                      onClick={() => removeLicense(id)}
                    />
                    <Button
                      className="ct-mobile-only touchable"
                      onClick={() => removeLicense(id)}
                    >{t("delete")}</Button>
                  </>
                  )}
                  {id === deletingId && error && <Alert>{t(error)}</Alert>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminListUserLicenses;
