import React, {Component} from 'react';
import {loadData, dataTypes} from '../../../actions/apiDataActions'
import {connect} from 'react-redux';
import ApiAdmin from '../../../api-admin';
import withRouter from '../withRouter';
import Button from 'react-bootstrap/Button';
import Translated from './../Translated';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import withTranslate from './../withTranslate';
import Spinner from './../../feature/Spinner';
import Alert from 'react-bootstrap/Alert';

export class SchoolAdd extends Component {

  constructor(props, context) {
    super(props, context);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      id: '',
      email: ''
    }
  }

  addSchool(id, email) {
    this.props.dispatch(loadData(dataTypes.SCHOOL_ADD, ApiAdmin.addSchool, id, email));
  }

  onSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    this.addSchool(this.state.id, this.state.email);
  }

  renderSchoolLink() {
    if (!this.props.data) {
      return null;
    }
    return (
        <div>
          {this.props.data.school.id}
          &nbsp;
          <Button onClick={() => {
            this.props.navigate(`/school-settings/${this.props.data.school.id}`)
          }}>
            <Translated translationKey="edit"/>
          </Button>
        </div>
    );
  }

  renderError() {
    if (this.props.error) {
      return <Alert>{this.props.error}</Alert>
    }
    return null;
  }

  render() {
    if (this.props.isFetching) {
      return <Spinner/>
    }

    const t = this.props.translate;

    return (
        <div className="school-list">
          {this.renderError()}
          <div id="user-search-container">
            <Form onSubmit={(e) => {
              e.preventDefault()
            }} className="fade-in">
              <Form.Group>
                <InputGroup>
                  <Form.Control type="text" name="school_id" placeholder={t('id')}
                                value={this.state.id} onChange={(e) => this.setState({id: e.target.value})}/>
                </InputGroup>
                <InputGroup>
                  <Form.Control type="text" name="school_email" placeholder={t('email')}
                                value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>
                  <br/>
                </InputGroup>
                <InputGroup>
                  <Button
                      id="school-add-button"
                      onClick={(e) => this.onSubmit(e)}
                  >
                    {t('add_do')}
                  </Button>
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
          {this.renderSchoolLink()}
        </div>
    )
  }
}

let mapStateToProps = (state) => {
  const add = state.apiData[dataTypes.SCHOOL_ADD];
  return ({
    data: add.data,
    isFetching: add.isFetching,
    error: add.error,
  });
};

export default withRouter(connect(mapStateToProps)(withTranslate(SchoolAdd)));