// Takes a string str as parameter and returns a string that can be used as a hex color. Same inputs return same hex colors. 
export const stringToHexColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let hexColor = "#";
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      hexColor += ("00" + value.toString(16)).slice(-2);
    }
    return hexColor;
  };