import React, {Component} from 'react';
import {connect} from 'react-redux';
import withTranslate from '../../feature/withTranslate';
import Translated from '../../feature/Translated';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import ControlLabel from 'react-bootstrap/FormLabel';
import InvoiceList from '../../feature/admin/InvoiceList';
import classNames from 'classnames';


class InvoicingListPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.applyEditedFilters = this.applyEditedFilters.bind(this);

    this.state = {
      filters: null,
      editedFilters: {
        startDate: null,
        endDate: null,
        schoolId: null,
        openState: null
      }
    }
  }

  handleFilterChange(e) {
    const newFilters = Object.assign({}, this.state.editedFilters);
    newFilters[e.target.name] = e.target.value;
    this.setState({editedFilters: newFilters});
  }

  applyEditedFilters() {
    this.setState({filters: this.state.editedFilters});
  }

  makeFormGroup(label, fieldName, classes) {
    classes = classNames('settings-field', classes);
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          className={classes}
          name={fieldName}
          placeholder={label}
          value={this.state.editedFilters[fieldName] ? this.state.editedFilters[fieldName] : ''}
          onChange={this.handleFilterChange}/>
      </FormGroup>
    );
  }

  render() {
    return (
      <div className="page-container max-width-container">
        <h2><Translated translationKey="invoicing-list"/></h2>
        <div className="blue-box">
          {this.makeFormGroup(this.props.translate('start_date'), 'startDate')}
          {this.makeFormGroup(this.props.translate('end_date'), 'endDate')}
          <Button onClick={this.applyEditedFilters}><Translated translationKey="apply"/></Button>
        </div>
        <InvoiceList filters={this.state.filters}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(withTranslate(InvoicingListPage));
