import React, {Component} from 'react';
import withTranslate from '../../feature/withTranslate';
import ImageQuestion from '../../feature/admin/ImageQuestion';
import ApiAdmin from '../../../api-admin';
import Button from 'react-bootstrap/Button';
import Spinner from '../../feature/Spinner';
import TextInput from '../../feature/TextInput';
import Alert from 'react-bootstrap/Alert';
import Translated from '../../feature/Translated';
import DropdownInput from '../../feature/DropdownInput';

class AdminImagesPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.filterListener = this.filterListener.bind(this);
    this.sortListener = this.sortListener.bind(this);

    this.sortingValues = [
      [null, 'none'],
      ['reports', 'reports'],
      ['bookRefs', 'book_references'],
      ['assertions', 'assertions'],
      ['descriptions', 'descriptions']
    ];

    this.languages = Object.keys(LANGUAGE_SELECTIONS).map((lang) => [
      lang,
      LANGUAGE_SELECTIONS[lang],
    ]);

    this.state = {
      language: 'fi',
      category: 'b',
      id:"",
      assertion: '',
      questions: null,
      sortBy: null,
      isFetching: false,
      error: null
    }
  }

  fetchImages(category, lang, id) {
    if (this.state.isFetching) {
      return false;
    }
    this.setState({
      questions: null,
      isFetching: true
    });

    ApiAdmin.listImageQuestions(category, lang, id)
      .then(response => {
        if (response.error) {
          this.setState({
            error: response.error,
            isFetching: false,
          })
        } else {
          this.setState({
            questions: response.questions,
            isFetching: false,
            error: null
          })
        }
      });
  }

  filterListener(filter, value) {
    const state = {};
    state[filter] = value;
    this.setState(state);
  }

  sortListener(value) {
    this.setState({sortBy: value});
  }

  renderQuestions() {
    if (this.state.isFetching) {
      return (<Spinner />);
    }
    if (this.state.error) {
      return (<div><Alert><Translated translationKey={this.state.error}/></Alert></div>);
    }
    if (!this.state.questions) {
      return null;
    }

    switch(this.state.sortBy) {
      case 'reports':
        this.state.questions.sort(this.sortQuestionsOnReportsDesc);
        break;
      case 'assertions':
        this.state.questions.sort(this.sortQuestionsOnAssertions);
        break;
      case 'bookRefs':
        this.state.questions.sort(this.sortQuestionsOnBookRefs);
        break;
      case 'descriptions':
        this.state.questions.sort(this.sortQuestionsOnDescriptions);
        break;
      default:
        this.state.questions.sort(this.sortQuestionsOnId);
    }

    const elems = [];
    const assertionFilter = this.state.assertion;
    this.state.questions.forEach(imageQuestion => {
      if (assertionFilter === '' || assertionFilter === imageQuestion.assertionId.toString()) {
        elems.push(<ImageQuestion key={imageQuestion.id} question={imageQuestion} category={this.state.category}/>)
      }
    });
    return elems;
  }

  sortQuestionsOnId(question1, question2) {
    const id1 = question1.id;
    const id2 = question2.id;
    if (id1 === id2) {
      return 0;
    }
    return id1 > id2 ? 1 : -1;
  }

  sortQuestionsOnReportsDesc(question1, question2) {
    const len1 = question1.reports ? question1.reports.length : 0;
    const len2 = question2.reports ? question2.reports.length : 0;
    if (len1 === len2) {
      return 0;
    }
    return len1 > len2 ? -1 : 1;
  }

  sortQuestionsOnBookRefs(question1, question2) {
    const id1 = question1.bookReferenceIds ? question1.bookReferenceIds[0] : null;
    const id2 = question2.bookReferenceIds ? question2.bookReferenceIds[0] : null;
    if (id1 === id2) {
      return 0;
    }
    return id1 > id2 ? 1 : -1;
  }

  sortQuestionsOnDescriptions(question1, question2) {
    const id1 = question1.descriptions ? question1.descriptions[0].id : null;
    const id2 = question2.descriptions ? question2.descriptions[0].id : null;
    if (id1 === id2) {
      return 0;
    }
    return id1 > id2 ? 1 : -1;
  }

  sortQuestionsOnAssertions(question1, question2) {
    let ass1 = question1.assertion;
    let id1 = question1.assertionId.toString();
    if (id1 !== '') {
      ass1 = parseInt(id1.replace(/[^0-9]/g, ''));
    }

    let ass2 = question2.assertion;
    let id2 = question2.assertionId.toString();
    if (id2 !== '') {
      ass2 = parseInt(id2.replace(/[^0-9]/g, ''));
    }

    return ass1 > ass2 ? 1 : -1;
  }

  render() {
    const t = this.props.translate;
    return (
      <div className="page-container page-gutter max-width-container">
        <DropdownInput
          listener={(value) => this.filterListener("language", value)}
          value={this.state.language}
          title={this.languages.find(lang => lang[0] === this.state.language)[1]}
          items={this.languages}
        />
        <TextInput
          listener={(value) => this.filterListener("category", value)}
          value={this.state.category}
          placeholder="category"
          autoComplete="off"
        />
        <TextInput
          listener={(value) => this.filterListener("assertion", value)}
          value={this.state.assertion}
          placeholder="assertion"
          autoComplete="off"
        />
        <TextInput
          listener={(value) => this.filterListener("id", value)}
          value={this.state.id}
          placeholder="id"
          autoComplete="off"
        />
        <DropdownInput
          listener={(value) => this.sortListener(value)}
          value={this.state.sortBy}
          title="sorting"
          items={this.sortingValues}
        />
        <Button
          id="list-images-button"
          onClick={(e) =>
            this.fetchImages(
              this.state.category,
              this.state.language,
              this.state.id
            )
          }
        >
          {t("search_do")}
        </Button>
        {this.renderQuestions()}
      </div>
    );
  }
}

export default withTranslate(AdminImagesPage);

