import React, {Component} from 'react';
import Translated from '../../feature/Translated';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ApiAdmin from '../../../api-admin';
import DateUtil from '../../../utils/date';
import Spinner from "../Spinner";

export class InvoiceListItem extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      isExporting: false,
      exportError: null,
      exportResult: null
    }
  }

  renderExport() {
    if (this.state.isExporting) {
      return <Spinner/>
    }

    if (this.state.exportError) {
      return <Alert>{this.state.exportError}</Alert>
    }

    const netvisorId = this.props.invoiceData.netvisorId || (this.state.exportResult && this.state.exportResult.netvisorId);
    if (netvisorId) {
      return (
        <div>
          <Translated translationKey="netvisor_id"/>: {netvisorId}<br/>
          <Translated translationKey="netvisor_status"/>: {this.props.invoiceData.invoiceStatus}
        </div>
      )
    } else {
      return (
        <Button className="top-margin" onClick={() => this.exportInvoice()}>
          <Translated translationKey="export_invoice"/>
        </Button>
      )
    }
  }

  render() {

    const invoice = this.props.invoiceData;
    return (
      <div className="blue-inner-box tight">
        <div>
          {invoice.id} <span className="strong">{invoice.schoolId}</span>
          <div>
            <Translated translationKey="date"/>: {DateUtil.date(invoice.invoiceDate)}<br/>
            <Translated translationKey="due_date"/>: {DateUtil.date(invoice.dueDate)}<br/>
            <Translated translationKey="payment_received_date"/>: {invoice.paymentReceivedDate}
          </div>
        </div>
        <div>
          <Translated translationKey="total"/>: {invoice.total}€ <Translated translationKey="total_inc_vat"/>: <span
          className="strong">{invoice.totalIncVat}€</span>
        </div>
        {this.renderExport()}
      </div>
    );
  }

  exportInvoice() {
    this.setState({isExporting: true});
    return ApiAdmin.exportInvoices([this.props.invoiceData.id])
      .then(resp => {
        if (resp.error) {
          return this.setState({
            exportResult: null,
            exportError: resp.message,
            isExporting: false
          })
        }

        const updatedInvoice = resp.invoices.find(invoice => invoice.id === this.props.invoiceData.id);
        this.setState({
          exportError: null,
          exportResult: updatedInvoice,
          isExporting: false
        })
      })

  }
}

export default InvoiceListItem;