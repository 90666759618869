import React, {Component} from 'react';
import Translated from '../../feature/Translated';
import Button from 'react-bootstrap/Button';
import {IoReload} from 'react-icons/io5';
import Alert from 'react-bootstrap/Alert';
import ApiAdmin from '../../../api-admin';

export class ClearCaches extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {clearResponse: null};
  }

  clearCaches() {
    ApiAdmin.clearCaches()
      .then(response => {
        this.setState({
          clearResponse: JSON.stringify(response)
        })
      })
  }

  render() {
    return (
      <div>
        <Button onClick={() => this.clearCaches()}>
          <IoReload/>
          <Translated translationKey="clear_caches"/>
        </Button>
        {this.state.clearResponse ? <Alert>{this.state.clearResponse}</Alert> : null}
      </div>
    );
  }
}

export default ClearCaches;