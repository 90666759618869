const adminNavData = [
  {
    "id": 'search-users',
    "titleKey": "search_users",
  },
  {
    "id": 'school-add',
    "titleKey": "menu_add_school",
  },
  {
    "id": 'login-as-user',
    "titleKey": "login_as_user",
  },
  {
    "id": 'list-literals',
    "titleKey": "menu_list_literals",
  },
  {
    "id": 'list-images',
    "titleKey": "menu_list_images",
  },
  {
    "id": 'books',
    "titleKey": "menu_book",
  },
  {
    'id': 'invoicing',
    'titleKey': 'menu_invoicing'
  },
  {
    'id': 'webshop-orders',
    'titleKey': 'menu_webshop_orders'
  },
  {
    'id': 'email-events',
    'titleKey': 'email_events_page'
  },
  {
    "id": 'event-logs',
    "titleKey": "event_logs",
  },
  {
    "id": 'logout',
    "titleKey": "menu_logout",
  },
];

export default adminNavData;