import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../api-admin';
import TextInput from '../../feature/TextInput';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Translated from '../../feature/Translated';

class AdminLoginAsUserPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.requestLinkForUserId = this.requestLinkForUserId.bind(this);
    this.requestLinkForEmail = this.requestLinkForEmail.bind(this);

    this.state = {
      userIdData: null,
      userEmailData: null,
    }
  }

  requestLinkForUserId(userId) {
    api.getTokenForUserIdLogin(userId)
      .then(data => {
        this.setState({userIdData: data});
      })
  }

  requestLinkForEmail(email) {
    api.getTokenForUserEmailLogin(email)
      .then(data => {
        this.setState({userEmailData: data});
      })
  }

  renderLinkData(data) {
    if (!data) {
      return null;
    }
    else if (data.error) {
      return (
        <Alert><Translated translationKey={data.error} /></Alert>
      )
    } else {
      return (
        <div>
          <a href={data.url} target="_blank" rel="noopener noreferrer">{data.userName} &gt;&gt;</a>
        </div>
      )
    }
  }

  renderLoginAsUserId() {
    return (
      <div className="medium-top-margin">
        <Translated translationKey="user_id"/>
        <TextInput listener={(value) => this.userIdInput = value}/>
        <Button onClick={() => this.requestLinkForUserId(this.userIdInput)}>
          <Translated translationKey="search_do"/>
        </Button>
        {this.renderLinkData(this.state.userIdData)}
      </div>
    );
  }

  renderLoginAsUserEmail() {
    return (
      <div className="large-top-margin">
        <Translated translationKey="email"/>
        <TextInput listener={(value) => this.userEmailInput = value}/>
        <Button onClick={() => this.requestLinkForEmail(this.userEmailInput)}>
          <Translated translationKey="search_do"/>
        </Button>
        {this.renderLinkData(this.state.userEmailData)}
      </div>
    );
  }

  render() {
    return (
      <div id="school-settings-page" className="page-container max-width-container">
        <h2><Translated translationKey="login_as_user"/></h2>
        {this.renderLoginAsUserId()}
        {this.renderLoginAsUserEmail()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(AdminLoginAsUserPage);
