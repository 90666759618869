import React from "react";
import { Accordion as BootstrapAccordion } from "react-bootstrap";

const SingleItemAccordion = function ({ title, content, startExpanded, onClick }) {
  return (
    <BootstrapAccordion
      onClick={onClick}
      defaultActiveKey={startExpanded ? 0 : null}
      flush
      className="accordion vertical-margins"
    >
      <BootstrapAccordion.Item className="accordion-item" eventKey={0}>
        <BootstrapAccordion.Header className="accordion-header">
          {title}
        </BootstrapAccordion.Header>
        <BootstrapAccordion.Body className="accordion-body">
          {content}
        </BootstrapAccordion.Body>
      </BootstrapAccordion.Item>
    </BootstrapAccordion>
  );
};

export default SingleItemAccordion;
