import React, {Component} from 'react';
import PractiseLimits from '../../feature/PractiseLimits';
import withRouter from "../../feature/withRouter";

class AdminPractiseLimitsPage extends Component {

  render() {
    return (
      <div id="school-practise-limits-page" className="page-container max-width-container">
        <PractiseLimits schoolId={this.props.match.params.schoolId}/>
      </div>
    );
  }
}

export default withRouter(AdminPractiseLimitsPage)
