import React, {Component} from 'react';
import {connect} from 'react-redux';
import Translated from '../../feature/Translated';
import InvoiceList from '../../feature/admin/InvoiceList';
import withRouter from "../../feature/withRouter";

class SchoolInvoicesPage extends Component {

  constructor(props, context) {
    super(props, context);
  }

  getSchoolId() {
    if (this.props.match.params.schoolId) {
      return this.props.match.params.schoolId;
    } else {
      return this.props.token.get('schoolId')
    }
  }

  render() {
    return (
      <div className="page-container max-width-container">
        <h2><Translated translationKey="invoices"/></h2>
        <h3>{this.getSchoolId()}</h3>
        <InvoiceList filters={{schoolId: this.getSchoolId()}} immediate={true}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default withRouter(connect(mapStateToProps)(SchoolInvoicesPage));
