import React, {Component} from 'react';
import News from '../../feature/News';
import ClearCaches from '../../feature/admin/ClearCaches';
import SchoolSearch from '../../feature/admin/SchoolSearch';

class AdminDashboardPage extends Component {

  render() {
    return (
      <div className="page-container page-gutter max-width-container">
        <News />
        <ClearCaches />
        <br />
        <SchoolSearch/>
      </div>
    )
  }
}

export default AdminDashboardPage;

