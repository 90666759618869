import React, {Component} from 'react';
import api from '../../../api-admin';
import TextInput from '../../feature/TextInput';
import Translated from '../../feature/Translated';
import Spinner from '../../feature/Spinner';
import Button from 'react-bootstrap/Button';
import withTranslate from '../../feature/withTranslate';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

class AdminWebshopOrdersPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      searchTerm: '',
      orders: null,
      homePageUrl: null,
      error: null,
      isFetching: false,
      changeOrderStatusData: { isFetching: false, error: null },
      changedOrderId: null,
    };
    this.changedOrderRef = React.createRef();
    
  }

  componentDidUpdate() {
    if(this.state.changedOrderId && this.changedOrderRef.current) {
      this.changedOrderRef.current.scrollIntoView();
    }
  }

  fetchOrders(orderId) {
    if(!orderId) {
      this.state.changedOrderId = null;
    }
    this.setState({error: null, orders: null, isFetching: true});
    api.searchWebshopOrders(this.state.searchTerm)
      .then(data => {
        if (data.error) {
          this.setState({error: data.error, isFetching: false})
        } else {
          const {orders, homePageUrl} = data;
          orders.sort((orderA, orderB) => orderB.id - orderA.id);
          this.setState({error: null, orders, homePageUrl, isFetching: false})
        }
      })
  }

  changeOrderStatusToOk(orderId) {
    this.state.changedOrderId = orderId;
    this.setState({changeOrderStatusData: {
      isFetching:true,
      error: null
    }})
    api.changeOrderStatusToOk(orderId).then(response => {
      if (response.error) {
        this.setState({
          changeOrderStatusData: {
            isFetching: false,
            error: response.error,
          },
        });
      } else {
        this.setState({
          changeOrderStatusData: {
            isFetching: false,
            error: null,
          },
        });
        this.fetchOrders(orderId);
      }
    })
  }

  renderOrders() {
    return this.state.orders && this.state.orders.map(order => {
      const isOrderStatusChanged = order.id === this.state.changedOrderId;
      const ref = isOrderStatusChanged ? this.changedOrderRef : null;
      const rows = Object.keys(order).map(key => {
        if (key === 'orderDataDump') {
          return null;
        }
        
        if (key === 'status' && order.status !== "ok") {
          return (
            <div key={key}>
              <p>
                <strong>{key}: </strong>
                {order.status}&nbsp;
              </p>
              <Button
                onClick={() => {
                  this.changeOrderStatusToOk(order.id);
                }}
              >
                {this.props.translate("change_order_status_to_ok")}
                {this.state.changeOrderStatusData.isFetching ? (
                  <Spinner />
                ) : null}
              </Button>
              {this.state.changeOrderStatusData.error && isChanged ? (
                <Alert>
                  {this.props.translate(this.state.changeOrderStatusData.error)}
                </Alert>
              ) : null}
            </div>
          );
        }
        let value = JSON.stringify(order[key]);
        // Add some white space to make the text wrap.
        value = value.replace(/","/g, '", "');
        return (
          <div key={key}><span className="strong">{key}</span>: {value}</div>
        )
      });
      const orderPageUrl = this.state.homePageUrl + '/order/' + order.id + '/' + order.token;
      return (
        <div ref={ref} className="blue-box word-wrap" key={order.id}>
          {rows}
          <div>
            <a href={orderPageUrl} target="_blank" rel="noopener noreferrer">{this.props.translate('order_info_page')} &gt;&gt;</a>
          </div>
          <Link to={`/email-events/${order.customerEmail}`}>{this.props.translate("email_events_page")} &gt;&gt;</Link>
        </div>
      )
    })
  }

  render() {
    const spinner = this.state.isFetching && <Spinner />;
    const orders = this.renderOrders();
    const count = this.state.orders && <div>{this.props.translate('total')}: {this.state.orders.length}</div>;
    return (
      <div className="page-container max-width-container">
        <h2><Translated translationKey="menu_webshop_orders"/></h2>
        <div className="blue-box">
          <TextInput listener={(value) => this.setState({searchTerm: value, orders: null})} value={this.state.searchTerm} placeholder="search"
                     autoComplete="off"/>
          <Button disabled={!this.state.searchTerm || this.state.searchTerm.length < 3} id="search-button" onClick={() => this.fetchOrders()}>
            {this.props.translate("search_do")}
          </Button>
        </div>
        {spinner}
        {count}
        {orders}
      </div>
    );
  }
}

export default withTranslate(AdminWebshopOrdersPage);
