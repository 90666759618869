import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../api';
import ApiAdmin from "../../../api-admin"
import PreloadMiddlePage from '../../pages/PreloadMiddlePage';
import EditUser from '../../feature/EditUser';
import Alert from 'react-bootstrap/Alert';
import UserCategoryStatus from '../../feature/UserCategoryStatus';
import TestResultsList from '../../feature/results/TestResultsList';
import Translated from '../../feature/Translated';
import {IoAlertCircle} from 'react-icons/io5';
import UserBookStatuses from '../../feature/UserBookStatuses';
import {Roles, fillsRole} from '../../../data/roles';
import withRouter from "../../feature/withRouter";
import AdminSearchEmailEvents from '../../feature/admin/AdminSearchEmailEvents';
import AdminListUserLicenses from '../../feature/admin/AdminListUserLicenses';
import SingleItemAccordion from '../../feature/SingleItemAccordion';
import SubscriptionPeriods from '../../feature/admin/SubscriptionPeriods';
import LoginLogs from '../../feature/admin/LoginLogs';
import AdminListWebshopOrders from '../../feature/admin/AdminListWebshopOrders';
import UserComments from '../../feature/admin/UserComments';
import EventLogs from '../../feature/admin/EventLogs';

class EditUserPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.saveUserData = this.saveUserData.bind(this);
    this.changeUserEmail = this.changeUserEmail.bind(this);
    this.disconnectEmail = this.disconnectEmail.bind(this);
    this.removeEmailPassword = this.removeEmailPassword.bind(this);
    this.state = {
      userProfile: null,
      isFetching: true,
      isSaving: false,
      error: null,
      adminErrors: {
        changeEmailError:null,
        disconnectEmailError:null,
        removeEmailPasswordError:null,
      }
    };
  }

  saveUserData(userData, callback) {
    this.setState({
      isSaving: true
    })
    api.saveProfile(userData)
      .then(response => {
        if (response.error) {
          return this.setState({
            error: response.error,
            isSaving: false
          });
        }
        this.setState({
          userProfile: response,
          isSaving: false
        });
        if (callback) {
          callback();
        }
      });
  }

  changeUserEmail(email, callback) {
    this.setState({
      isSaving: true,
      adminErrors: {...this.state.adminErrors, changeEmailError: null}
    });
    ApiAdmin.changeEmail(this.state.userProfile.userData.id, email).then((response) => {
      if (response.error) {
        return this.setState({
          adminErrors: {...this.state.adminErrors, changeEmailError: response.error},
          isSaving: false,
        });
      }
      this.setState({
        userProfile: response.profile,
        isSaving: false,
      });
      if (callback) {
        callback();
      }
    });
  }

  disconnectEmail() {
    this.setState({
      isSaving: true,
      adminErrors: {...this.state.adminErrors, disconnectEmailError: null}
    });
    ApiAdmin.disconnectEmail(this.state.userProfile.userData.id).then((response) => {
      if (response.error) {
        return this.setState({
          adminErrors: {...this.state.adminErrors, disconnectEmailError: response.error},
          isSaving: false,
        });
      }
      this.setState({
        userProfile: response.profile,
        isSaving: false,
      });
    });
  }

  removeEmailPassword() {
    this.setState({
      isSaving: true,
      adminErrors: {...this.state.adminErrors, removeEmailPasswordError: null}
    });
    ApiAdmin.removeEmailPassword(this.state.userProfile.userData.id).then((response) => {
      if (response.error) {
        return this.setState({
          adminErrors: {...this.state.adminErrors, removeEmailPasswordError: response.error},
          isSaving: false,
        });
      }
      this.setState({
        userProfile: response.profile,
        isSaving: false,
      });
    });
  }

  componentDidMount() {
    this.fetchProfile(this.props.match.params.userId);
  }

  fetchProfile(userId) {
    this.setState({
      isFetching: true,
      userProfile: null,
      error: null,
    });

    api.getProfile(userId, true).then((response) => {
      if (response.error) {
        this.setState({
          userProfile: null,
          isFetching: false,
          error: response.error,
        });
      } else {
        this.setState({
          userProfile: response,
          isFetching: false,
          error: null,
        });
      }
    });
  }

  renderError(error) {
    return (
      <Alert id="edit-student-error">
        <Translated translationKey={error} />
      </Alert>
    );
  }

  renderCategoryStatus(categoryStatuses) {
    if (categoryStatuses.length === 0) {
      return (
        <Alert>
          <IoAlertCircle />
          &nbsp;&nbsp;&nbsp;
          <Translated translationKey="no_exercises_done" />
        </Alert>
      );
    }
    const userId = this.state.userProfile.userData.id;
    return categoryStatuses.map((catStatus) => {
      return (
        <UserCategoryStatus
          userId={userId}
          category={catStatus.category}
          acceptedAmount={catStatus.acceptedTestAmount}
          totalAcceptedRequired={catStatus.acceptedTestsRequired}
          rejectedAmount={catStatus.rejectedTestAmount}
          simple={true}
          key={catStatus.category}
        />
      );
    });
  }

  renderStatus(profile) {
    const isTeacher = fillsRole(profile.userData.role, Roles.TEACHER);
    if (isTeacher) {
      return null;
    }
    return (
      <div>
        {this.renderCategoryStatus(profile.status.categoryStatus)}
        <UserBookStatuses userId={profile.userData.id} />
        {profile.status.categoryStatus.length === 0 ? null : (
          <TestResultsList userId={profile.userData.id} />
        )}
      </div>
    );
  }

  renderEmailEvents(email) {
    return (
      <SingleItemAccordion
        title={<Translated translationKey="email_events_page" />}
        content={<AdminSearchEmailEvents propsEmail={email} />}
      />
    );
  }

  renderEventLogs(userId) {
    return (
      <SingleItemAccordion
        title={<Translated translationKey="event_logs" />}
        content={<EventLogs targetId={userId} targetType={"user"} />}
      />
    );
  }

  renderUser(profile) {
    const isTeacher = fillsRole(profile.userData.role, Roles.TEACHER);
    const hasEmail = !!profile.userData.email;

    return (
      <div id="edit-student-page">
        {isTeacher ? (
          <h2>
            <Translated translationKey="teacher" />
          </h2>
        ) : null}
        <EditUser
          isAdminView={true}
          adminErrors={this.state.adminErrors}
          resetAdminError={(key) =>
            this.setState({
              adminErrors: { ...this.state.adminErrors, [key]: null },
            })
          }
          profile={profile}
          saveUserMethod={this.saveUserData}
          isSaving={this.state.isSaving}
          changeUserEmail={this.changeUserEmail}
          disconnectEmail={this.disconnectEmail}
          removeEmailPassword={this.removeEmailPassword}
        />
        <SubscriptionPeriods userId={profile.userData.id} />
        <br />
        {profile.userData.activationDate ? this.renderStatus(profile) : null}
        <AdminListUserLicenses userId={profile.userData.id} />
        <AdminListWebshopOrders userEmail={profile.userData.email} />
        {hasEmail ? this.renderEmailEvents(profile.userData.email) : null}
        <LoginLogs userId={profile.userData.id} />
        {this.renderEventLogs(profile.userData.id)}
        <UserComments userId={profile.userData.id} />
      </div>
    );
  }

  render() {
    if (this.state.isFetching) {
      return <PreloadMiddlePage />;
    }

    return (
      <div
        id="edit-student-page"
        className="page-container max-width-container"
      >
        {this.state.error ? this.renderError(this.state.error) : null}
        {this.state.userProfile
          ? this.renderUser(this.state.userProfile)
          : null}
      </div>
    );
  }
}

export default withRouter(connect()(EditUserPage));
