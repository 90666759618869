import React, {Component, memo} from 'react';
import ZoomableImage from '../ZoomableImage';
import { IoCheckmarkSharp, IoClose } from "react-icons/io5";
import TextInput from '../TextInput';
import ApiAdmin from '../../../api-admin';
import Button from 'react-bootstrap/Button';
import Translated from '../Translated';
import Reports from './Reports';
import BookModalLink from '../BookModalLink';

class ImageQuestion extends Component {

  constructor(props, context) {
    super(props, context);
    this.bookReferenceListener = this.bookReferenceListener.bind(this);
    this.saveReference = this.saveReference.bind(this);

    this.state = {
      showPinch: -1,
      unsavedBookReference: '',
      referenceError: null
    }
  }

  renderQuestionAndAnswer(question) {
    const correctAnswer =
      question.isAssertionTruthy === true
        ? <IoCheckmarkSharp className="primary" />
        : <IoClose className="red"/>;

    return (
      <div className="test-result-image-question">
        {question.id}. &nbsp;
        {question.assertion} &nbsp;
        {correctAnswer} &nbsp;
      </div>
    );
  }

  renderExplanations(explanationsArr) {
    const elements = explanationsArr.map((exp) => {
      // Use images if svg is missing.
      const src = exp.imageUrl;

      let img = src ? <div><img className="test-result-image-explanation-image" src={src}/></div> : null;
      let text = <div>{exp.text}</div>;
      return (<div key={exp.id} className="test-result-image-explanation-wrapper">{img}{text}</div>)
    });
    return elements;
  }

  bookReferenceListener(refId) {
    this.setState({unsavedBookReference: refId});
  }

  saveReference() {
    ApiAdmin.addBookReference('image', this.props.question.id, this.props.category, this.state.unsavedBookReference)
      .then(response => {
        if (response.bookReference) {
          this.setState({referenceError: null});
          // SMELL Mutating props is just wrong but hey..
          this.props.question.bookReferenceIds.push(response.bookReference.bookReferenceId);
          this.forceUpdate();
        } else {
          this.setState({referenceError: response });
        }
      });
  }

  renderReports(reports) {
    return (reports) ? <Reports reports={reports}/> : null;
  }

  renderBookContent(question) {
    if (question.bookReferenceIds.length === 0) {
      return (
        <div>
          <TextInput listener={(value) => this.bookReferenceListener(value)}
                     value={this.state.unsavedBookReference} placeholder="book reference id"/>
          <Button onClick={() => this.saveReference()}>
            <Translated translationKey="save"/>
          </Button>
          {this.state.referenceError && <span><Translated translationKey={this.state.referenceError.error} /></span>}
        </div>
      );
    }
    return question.bookReferenceIds.map(refId => <BookModalLink contentId={refId} showId key={refId}/>);
  }

  renderQuestion(question) {
    const key = question.id;
    return (
      <div className="test-result-image-wrapper" key={key}>
        <ZoomableImage src={question.imageUrl} className="test-result-image-image"
                       modalClassName="test-result-image-modal"/>
        <div className="test-result-image-explanations-wrapper">
          {this.renderQuestionAndAnswer(question)}
          {this.renderExplanations(question.descriptions)}
          {this.renderBookContent(question)}
          {this.renderReports(question.reports)}
          <span className="smaller semi-transparent">{question.imageUrl}</span>
        </div>
      </div>
    );
  }

  render() {
    const question = this.props.question;
    return this.renderQuestion(question);
  }
}

export default memo(ImageQuestion);
