import React, {Component} from 'react';
import {loadData, dataTypes} from '../../../actions/apiDataActions'
import {connect} from 'react-redux';
import ApiAdmin from '../../../api-admin';
import withRouter from '../withRouter';
import Button from 'react-bootstrap/Button';
import Translated from './../Translated';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {IoSearch} from 'react-icons/io5';
import withTranslate from './../withTranslate';
import Spinner from './../../feature/Spinner';
import Alert from 'react-bootstrap/Alert';

export class SchoolSearch extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      term: '',
    }
  }

  searchSchools() {
    this.props.dispatch(loadData(dataTypes.SCHOOL_SEARCH, ApiAdmin.searchSchools, this.state.term));
  }

  onSubmit(e) {
    if (e) {
      e.preventDefault();
    }
    this.searchSchools(this.state.term);
  }

  editSchoolSettings(schoolId) {
    this.props.navigate('/school-settings/' + schoolId);
  }

  editSchoolLimits(schoolId) {
    this.props.navigate('/school-limits/' + schoolId);
  }

  orderOneTimeKeys(schoolId) {
    this.props.navigate('/school-orders/' + schoolId);
  }

  listInvoices(schoolId) {
    this.props.navigate('/school-invoices/' + schoolId);
  }

  renderSchoolItem(school) {
    return (
      <div key={school.id}>
        <div>
          {school.name}<br />
        </div>
        <div className="lighter semi-transparent">
          [{school.series}] {school.phoneNumber} {school.email} {school.streetAddress} {school.postalLocation}<br />
        </div>
        {school.id}
        <Button bsstyle="link" onClick={() => this.editSchoolSettings(school.id)}><Translated
          translationKey="edit"/></Button>
        <Button bsstyle="link" onClick={() => this.editSchoolLimits(school.id)}><Translated
          translationKey="limits"/></Button>
        <Button bsstyle="link" onClick={() => this.orderOneTimeKeys(school.id)}><Translated
          translationKey="orders"/></Button>
        <Button bsstyle="link" onClick={() => this.listInvoices(school.id)}><Translated
          translationKey="invoices"/></Button>
        <hr />
      </div>
    )
  }

  renderSchools() {
    if (this.props.isFetching) {
      return <Spinner />
    }

    const schools = this.props.schools;

    if (!schools) {
      return null;
    }

    if (schools.length === 0) {
      return <Alert className="top-margin-small"><Translated translationKey="no_search_results"/></Alert>
    }

    const schoolElems = [];
    schools.forEach(school => {
      schoolElems.push(this.renderSchoolItem(school));
    });
    return <div className="medium-top-margin">{schoolElems}</div>;
  }

  render() {
    const t = this.props.translate;

    return (
      <div className="school-list">
        <div id="user-search-container">
          <Form onSubmit={(e) => this.onSubmit(e)} className="fade-in">
            <Form.Group>
              <InputGroup>
                <InputGroup.Text><IoSearch/></InputGroup.Text>
                <Form.Control type="text" name="term" placeholder={t('search_schools')}
                             value={this.state.term} onChange={(e) => this.setState({term: e.target.value})}/>
                <InputGroup>
                  <Button
                    id="user-search-button"
                    onClick={(e) => this.onSubmit(e)}
                  >
                    {t('search_do')}
                  </Button>
                </InputGroup>
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
        {this.renderSchools()}
      </div>
    )
  }

}

let mapStateToProps = (state) => {
  const search = state.apiData[dataTypes.SCHOOL_SEARCH];
  return ({
    schools: search.data,
    isFetching: search.isFetching,
    error: search.error,
  });
};

export default withRouter(connect(mapStateToProps)(withTranslate(SchoolSearch)));