import React, {Component} from 'react';
import withTranslate from './withTranslate';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Translated from './Translated';

class DropdownInput extends Component {

  createMenuItems() {
    const menuItems = [];

    this.props.items.forEach(item => {
      let value = item[0];
      let translationKey = item[1];
      menuItems.push(
          <Dropdown.Item
              id={value}
              key={value}
              onClick={() => {
                this.props.listener(value);
              }}>
            <Translated translationKey={translationKey}/>
          </Dropdown.Item>
      );
    });
    return menuItems;
  }

  render() {
    const menuItems = this.createMenuItems();
    const label = this.props.translate(this.props.title);

    return (
      <ButtonToolbar className="settings-category-limit">
        <DropdownButton
          id='select-order-batch-dd'
          bsstyle={this.props.itemStyle ? this.props.itemStyle : "warning"}
          title={label}>
          {menuItems}
        </DropdownButton>
      </ButtonToolbar>
    );
  }
}

export default withTranslate(DropdownInput);
