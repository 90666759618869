import React from "react";
import EventLogs from "../../feature/admin/EventLogs";

const AdminEventLogsPage = () => {
  return (
    <div className="page-container page-gutter max-width-container">
      <div className="blue-box">
        <EventLogs searchEnabled />
      </div>
    </div>
  );
};

export default AdminEventLogsPage;
