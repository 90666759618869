import React, { useEffect, useState } from "react";
import AdminSearchEmailEvents from "../../feature/admin/AdminSearchEmailEvents";

const AdminSearchEmailEventsPage = () => { 
  return (
    <div className="page-container max-width-container">
      <AdminSearchEmailEvents />
    </div>
  );
 
}
  

export default AdminSearchEmailEventsPage;
