import React, {Component} from 'react';
import {connect} from 'react-redux';
import withTranslate from '../../feature/withTranslate';
import api from '../../../api';
import Translated from '../../feature/Translated';
import Spinner from './../../feature/Spinner';
import Alert from 'react-bootstrap/Alert';
import FormGroup from 'react-bootstrap/FormGroup';
import ControlLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import DateUtil from '../../../utils/date';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';
import SchoolComments from '../../feature/admin/SchoolComments';
import Logger from '../../../utils/logger';
import withRouter from "../../feature/withRouter";
import SingleItemAccordion from '../../feature/SingleItemAccordion';
import EventLogs from '../../feature/admin/EventLogs';

class AdminSchoolSettingsPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.handleSchoolInputChange = this.handleSchoolInputChange.bind(this);

    this.okToasterTimeout = null;

    this.state = {
      isLoading: true,
      isSending: false,
      hasChanges: false,
      isSaveOk: null,
      error: null,
      school: null,
    }
  }

  getSchoolId() {
    if (this.props.match.params.schoolId) {
      return this.props.match.params.schoolId;
    } else {
      return this.props.token.get('schoolId')
    }
  }

  componentWillUnmount() {
    clearTimeout(this.okToasterTimeout);
  }

  componentDidMount() {
    if (!this.props.token) {
      return;
    }
    const id = this.getSchoolId();
    api.getSchoolData(id)
        .then(response => {
          if (response.error) {
            return this.setState({
              error: response.error,
              isSending: false
            });
          }
          this.setState({
            error: null,
            isLoading: false,
            isSending: false,
            school: response.school,
          })
        })
        .catch(err => {
          Logger.error(err);
        })
  }

  postSettings() {
    if (!this.props.token) {
      return;
    }
    this.setState({
      error: null,
      isSending: true,
      isSaveOk: null,
    });
    api.setSchoolData(this.getSchoolId(), this.state.school)
        .then(response => {
          if (response.error) {
            return this.setState({
              error: response.error,
              isSaveOk: false,
              isSending: false
            });
          }
          this.okToasterTimeout = setTimeout(() => this.setState({isSaveOk: null}), 5000);
          this.setState({
            hasChanges: false,
            error: null,
            isSaveOk: true,
            isSending: false,
            school: response.school
          });
        });
  }

  handleSchoolInputChange(e) {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.updateSchoolState(e.target.name, value);
  }

  updateSchoolState(field, value) {
    const fieldOverlay = {};
    fieldOverlay[field] = value;
    const newSchoolObj = Object.assign(this.state.school, fieldOverlay);
    this.setState({school: newSchoolObj, hasChanges: true});
  }

  makeFormGroup(label, fieldName, classes) {
    classes = classNames('settings-field', classes);
    return (
        <FormGroup>
          <ControlLabel>{label}</ControlLabel>
          <FormControl
              className={classes}
              name={fieldName}
              placeholder={label}
              value={this.state.school[fieldName] ? this.state.school[fieldName] : ''}
              onChange={this.handleSchoolInputChange}/>
        </FormGroup>
    );
  }

  makeCheckbox(label, fieldName, classes) {
    classes = classNames('settings-field', classes);
    return (
        <Form.Check
            type="checkbox"
            className={classes}
            name={fieldName}
            checked={(this.state.school[fieldName])}
            onChange={this.handleSchoolInputChange}
            label={label}
        >
        </Form.Check>
    );
  }

  buildAmortizationSelection() {
    const daysLabel = ' ' + this.props.translate('days');
    const selections = [2, 7, 15, 30, 45];
    const currentValue = this.state.school.userAmortizationPeriodDays;

    return (
        <ButtonToolbar className="settings-category-limit">
          <DropdownButton
              id='amortizationSelection'
              bsstyle="warning"
              title={currentValue + daysLabel}>
            {selections.map((selectableValue => {
              return <Dropdown.Item
                  id={selectableValue}
                  key={selectableValue}
                  onClick={() => {
                    this.updateSchoolState('userAmortizationPeriodDays', selectableValue);
                  }}>
            <span>{selectableValue === currentValue
                ? <span className="settings-selected-dropdown-value ">{selectableValue + daysLabel}</span>
                : selectableValue + daysLabel}</span>
              </Dropdown.Item>
            }))}
          </DropdownButton>
        </ButtonToolbar>
    );
  }

  editSchoolLimits(schoolId) {
    this.props.navigate('/school-limits/' + schoolId);
  }

  orderOneTimeKeys(schoolId) {
    this.props.navigate('/school-orders/' + schoolId);
  }

  listInvoices(schoolId) {
    this.props.navigate('/school-invoices/' + schoolId);
  }

  editTeachers(schoolId) {
    this.props.navigate('/school-teachers/' + schoolId);
  }

  renderEventLogs(schoolId) {
    return (
      <SingleItemAccordion
        title={<Translated translationKey="event_logs" />}
        content={
          <EventLogs targetId={schoolId} targetType={"school"} />
        }
      />
    );
  }

  renderSchoolActions() {
    const school = this.state.school;
    return (
      <div>
        <div key={school.id}>
          <Button
            bsstyle="link"
            onClick={() => this.editSchoolLimits(school.id)}
          >
            <Translated translationKey="limits" />
          </Button>
          <Button
            bsstyle="link"
            onClick={() => this.orderOneTimeKeys(school.id)}
          >
            <Translated translationKey="orders" />
          </Button>
          <Button bsstyle="link" onClick={() => this.listInvoices(school.id)}>
            <Translated translationKey="invoices" />
          </Button>
          <Button bsstyle="link" onClick={() => this.editTeachers(school.id)}>
            <Translated translationKey="teachers" />
          </Button>
        </div>
        {this.renderEventLogs(school.id)}
        <hr />
      </div>
    );
  }

  renderForm() {
    const errorMessage = this.state.error
        ? <Alert bsstyle="warning"><Translated translationKey={this.state.error}/></Alert>
        : null;

    const saveOkMessage = this.state.isSaveOk
        ? <Alert bsstyle="success">OK</Alert>
        : null;

    const school = this.state.school;
    const saveDisabled = this.state.isSending;

    return (
      <div>
        <h3>{school.id.toUpperCase()}</h3>
        {this.renderSchoolActions()}
       
        <div className="white-box">
          <form>
            <fieldset disabled={this.state.isSending}>
              {this.makeFormGroup(this.props.translate("vat_id"), "vatID")}
              {this.makeFormGroup(this.props.translate("name"), "name")}
              {this.makeFormGroup(
                this.props.translate("street_address"),
                "streetAddress"
              )}
              {this.makeFormGroup(
                this.props.translate("zip_code"),
                "postalNumber",
                "settings-limited-field"
              )}
              {this.makeFormGroup(
                this.props.translate("postal_location"),
                "postalLocation",
                "settings-limited-field"
              )}
              {this.makeFormGroup(
                this.props.translate("phone_number"),
                "phoneNumber",
                "settings-limited-field"
              )}
              {this.makeFormGroup(this.props.translate("email"), "email")}
              {this.makeFormGroup(this.props.translate("url"), "url")}
              {this.makeFormGroup(
                this.props.translate("student_codes_batch_amount"),
                "studentCodesBatchAmount"
              )}
              {this.makeFormGroup(this.props.translate("password"), "password")}
              {this.makeFormGroup(
                this.props.translate("license_free_until", {
                  date: DateUtil.dateTime(school.prepaidDate),
                }),
                "prepaidDate"
              )}
              {this.makeFormGroup(
                this.props.translate("soft_closing_date", {
                  date: DateUtil.dateTime(school.softClosingDate),
                }),
                "softClosingDate"
              )}
              {this.makeFormGroup(
                this.props.translate("closing_date", {
                  date: DateUtil.dateTime(school.closingDate),
                }),
                "closingDate"
              )}
              {this.makeFormGroup(
                this.props.translate("invoicing_interval_months", {
                  date: school.invoicingIntervalMonths,
                }),
                "invoicingIntervalMonths"
              )}
              {this.makeFormGroup(
                this.props.translate("user_discount_percentage"),
                "userDiscountPercentage"
              )}
              {this.makeFormGroup(
                this.props.translate("student_amount"),
                "estimatedStudents"
              )}
              {this.makeFormGroup(
                this.props.translate("school_series"),
                "series"
              )}
              {this.makeFormGroup(
                this.props.translate("parent_school_id"),
                "parentSchoolId"
              )}
              {this.makeFormGroup(
                this.props.translate("preferred_language"),
                "preferredLanguageId"
              )}

              {this.makeCheckbox(
                this.props.translate("allow_closed_reminders"),
                "allowClosedReminders"
              )}
              {this.makeCheckbox(
                this.props.translate("allow_receipt_send"),
                "allowReceiptSend"
              )}
              {this.makeCheckbox(this.props.translate("is_closed"), "isClosed")}
              {this.makeCheckbox(
                this.props.translate("has_teacher_page"),
                "hasTeacherPage"
              )}

              <div>
                <Translated translationKey="user_amortization_title" />
                {this.buildAmortizationSelection()}
              </div>

              <h4>
                <Translated translationKey="invoice_details" />
              </h4>
              {this.makeFormGroup(
                this.props.translate("invoice_receiver"),
                "invoiceName"
              )}
              {this.makeFormGroup(
                this.props.translate("street_address"),
                "invoiceStreetAddress"
              )}
              {this.makeFormGroup(
                this.props.translate("zip_code"),
                "invoicePostalNumber",
                "settings-limited-field"
              )}
              {this.makeFormGroup(
                this.props.translate("postal_location"),
                "invoicePostalLocation",
                "settings-limited-field"
              )}
              {this.makeFormGroup(
                this.props.translate("cost_center"),
                "invoiceCostCenter"
              )}

              <h4>
                <Translated translationKey="e_invoice_details" />
              </h4>
              {this.makeFormGroup(
                this.props.translate("e_invoice_address"),
                "eInvoiceAddress"
              )}
              {this.makeFormGroup(
                this.props.translate("e_invoice_operator_code"),
                "eInvoiceOperatorCode"
              )}

              <div className="centered">
                {errorMessage ? errorMessage : saveOkMessage}
                <Button
                  id="school-settings-submit-button"
                  disabled={saveDisabled}
                  bsstyle={this.state.hasChanges ? "danger" : undefined}
                  onClick={() => (!saveDisabled ? this.postSettings() : null)}
                >
                  {this.state.isSending ? (
                    <Spinner className="primary" />
                  ) : (
                    <Translated translationKey="save" />
                  )}
                </Button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }

  render() {
    return (
        <div id="school-settings-page" className="page-container max-width-container">
          {this.state.isLoading
              ? <Spinner className="primary"/>
              : (
                  <div>
                    {this.renderForm()}
                    <SchoolComments schoolId={this.getSchoolId()}/>
                    <br/>
                  </div>
              )
          }
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default withRouter(connect(mapStateToProps)(withTranslate(AdminSchoolSettingsPage)));
