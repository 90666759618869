import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from '../../../api-admin';
import TextInput from '../../feature/TextInput';
import Translated from '../../feature/Translated';
import Spinner from '../../feature/Spinner';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FormControl from 'react-bootstrap/FormControl';

class PreCreateKeysPage extends Component {

  constructor(props, context) {
    super(props, context);
    this.postCreate = this.postCreate.bind(this);

    this.state = {
      createdOrders: null,
      error: null,
      errorMsg: null,
      isCreating: false
    }
  }

  postCreate(schoolIdsStr, keyAmountPerSchool) {
    let amount = parseInt(keyAmountPerSchool);
    if (isNaN(amount) || amount < 1) {
      return this.setState({error: 'invalid_key_amount'});
    }
    if (!schoolIdsStr) {
      return this.setState({error: 'invalid_school_ids'});
    }
    const cleaned = schoolIdsStr.replace(/\s/g, '');
    const schoolIdsArr = cleaned.split(',');
    if (schoolIdsArr.length < 1) {
      return this.setState({error: 'invalid_school_ids'});
    }
    this.setState({errorMsg: null, error: null, createdOrders: null, isCreating: true});
    api.precreateOneTimeKeys(schoolIdsArr, parseInt(keyAmountPerSchool))
      .then(data => {
        if (data.error) {
          this.setState({error: data.error, errorMsg: data.message, createdOrders: null, isCreating: false});
        } else {
          this.setState({error: null, errorMsg: null, createdOrders: data.orders, isCreating: false});
        }
      })
  }

  renderAmountInput() {
    return (
      <div className="medium-top-margin">
        <Translated translationKey="amount_per_school"/>
        <TextInput listener={(value) => {
          this.amountPerSchool = value
        }}/>
      </div>
    );
  }

  renderSchoolIdsInput() {
    return (
      <div className="top-margin">
        <Translated translationKey="school_ids"/>
        <FormControl componentclass="textarea"
                     name="schoolIds"
                     onChange={(e) => {
                       this.schoolIdsStr = e.target.value
                     }}/>
      </div>
    );
  }

  renderError() {
    if (this.state.error) {
      return (
        <Alert className="top-margin fade-in">
          <Translated translationKey={this.state.error}/>
          <div>
            {this.state.errorMsg}
          </div>
        </Alert>
      );
    }
    return null;
  }

  renderLoading() {
    if (this.state.isCreating) {
      return (<div className="top-margin"><Spinner/></div>);
    } else {
      return null;
    }
  }

  renderOrders() {
    if (!this.state.createdOrders) {
      return null;
    }
    const elems = [];
    this.state.createdOrders.forEach(order => {
      elems.push(
        <div key={order.id}>
          {order.id} {order.schoolId} {order.deliveryAddress}
        </div>
      );
    });
    return (
      <div>
        {elems}
      </div>
    );
  }

  render() {
    return (
      <div id="school-settings-page" className="page-container max-width-container">
        <h2><Translated translationKey="pre_create_one_time_keys"/></h2>
        {this.renderAmountInput()}
        {this.renderSchoolIdsInput()}
        <Button className="top-margin" onClick={() => this.postCreate(this.schoolIdsStr, this.amountPerSchool)}>
          <Translated translationKey="create"/>
        </Button>
        {this.renderLoading()}
        {this.renderError()}
        {this.renderOrders()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    token: state.login.get('token')
  });
};

export default connect(mapStateToProps)(PreCreateKeysPage);
